import React, { Suspense, lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import './styles/main.scss';
import App from './core/App';
import { TitledRoute, AuthenticatedRoute, PermissionRoute } from './core/components/RouteComponents';
import { LoadingIndicator } from './core/components';
import { permissions } from '@seriously/common';
import useUserName from './core/auth/hooks/useUserName';
import { NEW_PLAYER_PAGE_PATH, OLD_PLAYER_PAGE_PATH } from './constants/routePaths';

// NOTE: Lazy loading components reduces bundle size
// https://reactjs.org/docs/code-splitting.html

// Core
const LoginPage = lazy(() => import('./core/auth/components/LoginPage'));
const NotFoundPage = lazy(() => import('./core/components/NotFoundPage'));

// BF1
const Fiendex = lazy(() => import("./bestfiends/fiendex"));
const SupportCodeListPage = lazy(() => import('./bestfiends/supportCodes/components/SupportCodeListPage'));
const SupportCodeCreatePage = lazy(() => import('./bestfiends/supportCodes/components/SupportCodeCreatePage'));
const BfBuildArchiver = lazy(() => import('./bestfiends/components/BfBuildArchiver'));
const BfPlayer = lazy(() => import('./bestfiends/player'));
const BfPlayerPageOld = lazy(() => import('./bestfiends/components/BfPlayerPage'));
const SearchPage = lazy(() => import('./bestfiends/search/components/SearchPage'));
const GlobalTeamEvent = lazy(() => import('./bestfiends/globalTeamEvent/components/GlobalTeamEvent'));
const VersionConfigListPage = lazy(() => import('./bestfiends/versionConfigs/components/VersionConfigListPage'));
const VersionConfigPage = lazy(() => import('./bestfiends/versionConfigs/components/VersionConfigPage'));
const LevelConfigListPage = lazy(() => import('./bestfiends/levelConfig/components/LevelConfigListPage'));
const LevelConfigPage = lazy(() => import('./bestfiends/levelConfig/components/LevelConfigPage'));
const BfAnalyticsPage = lazy(() => import('./bestfiends/components/BfAnalyticsPage'));
const CDOverviewPage = lazy(() => import('./bestfiends/cheaterDetection/components/CDOverviewPage'));
const EventPage = lazy(() => import('./bestfiends/events/components/EventPage'));
const EventList = lazy(() => import('./bestfiends/events/components/EventList'));
const LeaderboardsRoot = lazy(() => import('./bestfiends/leaderboards/components/LeaderboardsRoot'));
const ServerConfiguration  = lazy(() => import('./bestfiends/serverConfiguration'));
const DevTools = lazy(() => import('./bestfiends/devTools/components/DevTools'));
const BFCDFPage = lazy(() => import('./bestfiends/components/BFCDFPage'));
const DashboardConfiguration = lazy(() => import('./bestfiends/dashboardConfiguration'));
const LevelReleases = lazy(() => import('./bestfiends/levelReleases'));

// Common
const UserDetails = lazy(() => import('./users/UserDetails'));
const PlayerQueryPage = lazy(() => import('./bf-common/playerQuery/components/PlayerQueryPage'));
const ExamplePage = lazy(() => import('./bf-common/exampleModule/components/ExamplePage'));

// Company
const CompanyAnalyticsPage = lazy(() => import('./company/components/CompanyAnalyticsPage'));
const AccessAndErasure = lazy(() => import('./company/accessAndErasure/components/AccessAndErasureRoot'))
const AdsRoot = lazy(() => import('./company/ads/components/AdsRoot'));

// Stars
const PhoenixCDFPage = lazy(() => import('./phoenix/components/PhoenixCDFPage'));
const PhoenixBuildArchiver = lazy(() => import('./phoenix/components/PhoenixBuildArchiver'));
const PhoenixPlayers = lazy(() => import('./phoenix/players/components/PlayersRoot'));
const PhoenixLeaderboards = lazy(() => import('./phoenix/leaderboards/components/LeaderboardsRoot'));
const PhoenixTournaments = lazy(() => import('./phoenix/tournaments/components/TournamentsRoot'));
const PhoenixEventConfigurations = lazy(() => import('./phoenix/tournamentManagement/components/EventConfigurationsRoot'));
const SegmentationManagementPage = lazy(() => import('./phoenix/susan/components/SegmentationManagementPage'));
const UserOverridesPage = lazy(() => import('./phoenix/userOverrides/components/UserOverridesPage'));
const InventoryValidator = lazy(() => import('./phoenix/inventoryValidator/components/InventoryValidatorRoot'));
const ServerSettings = lazy(() => import('./phoenix/serverSettings/components/ServerSettingsRoot'));
const PhoenixAnalyticsPage = lazy(() => import('./phoenix/components/PhoenixAnalyticsPage'));
const PhoenixTeams = lazy(() => import('./phoenix/teams/components/TeamsRoot'));
const InventoryManagement = lazy(() => import('./phoenix/inventoryManagement/components/InventoryManagementRoot'));

const environments = [
  {
    team: 'BF1',
    environment: 'Dev',
    url: 'https://bf1-dev.dashboard.cloud.seriously.com'
  },
  {
    team: 'BF1',
    environment: 'Staging',
    url: 'https://bf1-staging.dashboard.cloud.seriously.com'
  },
  {
    team: 'Stars',
    environment: 'Dev',
    url: 'https://stars-dev.dashboard.cloud.seriously.com'
  },
  {
    team: 'Stars',
    environment: 'QA',
    url: 'https://stars-qa.dashboard.cloud.seriously.com'
  },
  {
    team: 'Stars',
    environment: 'Staging',
    url: 'https://stars-staging.dashboard.cloud.seriously.com'
  },
  {
    team: 'Tech',
    environment: 'Dev',
    url: 'https://dashboard-tech.cloud.seriously.com'
  },
  {
    team: '',
    environment: 'Production',
    url: 'https://dashboard.cloud.seriously.com'
  }
];

const EnvironmentsList = () => (
  <table style={{ maxWidth: 800, width: '100%', margin: '2em', lineHeight: 1.3 }}>
    <thead>
      <tr
        style={{
          textAlign: 'left',
          fontWeight: 'bold',
          textTransform: 'uppercase'
        }}
      >
        <th>Team</th>
        <th>Environment</th>
        <th>Url</th>
      </tr>
    </thead>
    <tbody>
      {environments.map(({ team, environment, url }, i) => (
        <tr key={team + '-' + environment} style={{ borderTop: i > 0 ? '1px solid lightgrey' : null }}>
          <td>{team}</td>
          <td>{environment}</td>
          <td>
            <a href={url} target='_blank'>
              {url}
            </a>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const AuthenticatedRoutes = () => {
  const userName = useUserName();

  return (
    <App userName={userName}>
      <Suspense fallback={<LoadingIndicator />}>
        <Switch>
          <TitledRoute path='/user' component={UserDetails} />
          {/* COMPANY */}
          <PermissionRoute path='/company' permissions={['COMPANY']}>
            <Switch>
              <PermissionRoute
                exact
                path='/company/analytics-kpi'
                component={CompanyAnalyticsPage}
                title={'Company: Analytics KPI'}
                permissions={['ALLOWED_TO_SEE_ANALYTICS']}
              />

              <PermissionRoute path='/company/access-and-erasure' permissions={['ALLOWED_TO_USE_ACCESS_AND_ERASURE']}>
                <TitledRoute
                  path='/company/access-and-erasure'
                  component={AccessAndErasure}
                  title={'Company: Access & Erasure'}
                />
              </PermissionRoute>

              <TitledRoute path='/company/ads' component={AdsRoot} title={'Company: Ads'} />
            </Switch>
          </PermissionRoute>

          {/* BEST_FIENDS */}
          <PermissionRoute path='/bf' permissions={['BEST_FIENDS']}>
            <Switch>
              <TitledRoute exact path='/bf/fiendex' component={Fiendex} />
              <TitledRoute exact path='/bf/fiendex/:page' component={Fiendex} />
              <TitledRoute path='/bf/dashboardConfiguration' component={DashboardConfiguration} />
              <TitledRoute
                exact
                path='/bf/supportCode'
                component={SupportCodeListPage}
                title={'BF: Support Code'}
              />
              <TitledRoute
                exact
                path='/bf/supportCode/create'
                component={SupportCodeCreatePage}
                title={'BF: Create Support Code'}
              />
              <TitledRoute
                path='/bf/supportCode/:code'
                component={SupportCodeListPage}
                title={'BF: Support Code'}
              />
              <PermissionRoute path='/bf/builds' permissions={['ALLOWED_TO_SEE_BUILD_ARCHIVER']}>
                <TitledRoute
                  exact
                  path='/bf/builds'
                  component={BfBuildArchiver}
                  title={'BF: Build Archiver'}
                />
                <TitledRoute
                  path='/bf/builds/:buildNumber'
                  component={BfBuildArchiver}
                  title={'BF: Build Archiver'}
                />
              </PermissionRoute>
              <PermissionRoute path={NEW_PLAYER_PAGE_PATH} permissions={['ALLOWED_TO_SEE_PLAYERS']}>
                <Switch>
                  <TitledRoute exact path={NEW_PLAYER_PAGE_PATH} component={BfPlayer} title={'BF: Player'} />
                  <TitledRoute exact path={`${NEW_PLAYER_PAGE_PATH}/:id`} component={BfPlayer} title={'BF: Player'} />
                  <TitledRoute exact path={`${NEW_PLAYER_PAGE_PATH}/:id/:view`} component={BfPlayer} title={'BF: Player'} />
                </Switch>
              </PermissionRoute>
              <PermissionRoute path={OLD_PLAYER_PAGE_PATH} permissions={['ALLOWED_TO_SEE_PLAYERS']}>
                <Switch>
                  <PermissionRoute
                    exact
                    path={`${OLD_PLAYER_PAGE_PATH}/query`}
                    component={PlayerQueryPage}
                    title={'BF: Player Query'}
                    permissions={['ALLOWED_TO_EDIT_PLAYERS']}
                  />
                  <TitledRoute
                    exact
                    path={OLD_PLAYER_PAGE_PATH}
                    component={BfPlayerPageOld}
                    title={'BF: Player'}
                  />
                  <TitledRoute
                    exact
                    path={`${OLD_PLAYER_PAGE_PATH}/:id`}
                    component={BfPlayerPageOld}
                    title={'BF: Player ${id}'}
                  />
                  <TitledRoute
                    exact
                    path={`${OLD_PLAYER_PAGE_PATH}/:id/:view`}
                    component={BfPlayerPageOld}
                    title={'BF: Player ${view} ${id}'}
                  />
                  <Redirect to='/404' />
                </Switch>
              </PermissionRoute>

              <PermissionRoute path='/bf/search' permissions={['ALLOWED_TO_SEE_PLAYERS']}>
                <TitledRoute
                  exact
                  path='/bf/search/'
                  component={SearchPage}
                  title={'BF: Search players'}
                />
                <TitledRoute
                  exact
                  path='/bf/search/:view'
                  component={SearchPage}
                  title={'BF: Search players ${view}'}
                />
              </PermissionRoute>

              <TitledRoute
                exact
                path='/bf/liveOpsEvents/'
                component={GlobalTeamEvent}
                title={'BF: Live Ops Event'}
              />

              <TitledRoute
                exact
                path='/bf/versionConfig'
                component={VersionConfigListPage}
                title={'BF: Version Config'}
              />
              <PermissionRoute
                path='/bf/versionConfig/create'
                component={VersionConfigPage}
                title={'BF: Create Version Config'}
                permissions={['ALLOWED_TO_EDIT_VERSION_CONFIGS']}
              />
              <TitledRoute
                path='/bf/versionConfig/:id'
                component={VersionConfigPage}
                title={'BF: Level Config ${id}'}
              />

              <TitledRoute
                exact
                path='/bf/levelConfig'
                component={LevelConfigListPage}
                title={'BF: Level Config'}
              />
              <PermissionRoute
                path='/bf/levelConfig/create'
                component={LevelConfigPage}
                title={'BF: Create Level Config'}
                permissions={['ALLOWED_TO_EDIT_LEVEL_CONFIGS']}
              />
              <TitledRoute
                path='/bf/levelConfig/:id'
                component={LevelConfigPage}
                title={'BF: Level Config ${id}'}
              />
              <TitledRoute
                title='Level releases'
                path='/bf/level-releases'
                component={LevelReleases}
                permissions={permissions.ALLOWED_TO_SEE_LEVEL_RELEASES}
              />
              <PermissionRoute path='/bf/analytics' permissions={['ALLOWED_TO_SEE_ANALYTICS']}>
                <Switch>
                  <TitledRoute
                    path='/bf/analytics/:dashboard'
                    component={BfAnalyticsPage}
                    title={'BF: Analytics'}
                  />
                  <TitledRoute
                    path='/bf/analytics'
                    component={BfAnalyticsPage}
                    title={'BF: Analytics'}
                  />
                  <Redirect to='/404' />
                </Switch>
              </PermissionRoute>

              <TitledRoute
                path='/bf/cheaters'
                component={CDOverviewPage}
                title={'BF: Cheater Detection'}
              />

              <PermissionRoute
                path='/bf/events/create'
                component={EventPage}
                title={'BF: Create Event'}
                permissions={['ALLOWED_TO_EDIT_EVENTS']}
              />
              <TitledRoute path='/bf/events/:id' component={EventPage} title={'BF: Event ${id}'} />
              <TitledRoute path='/bf/events' component={EventList} title={'BF: Events'} />

              <PermissionRoute
                path='/bf/leaderboards'
                component={LeaderboardsRoot}
                title={'BF: Leaderboards'}
                permissions={['ALLOWED_TO_SEE_LEADERBOARDS']}
              />

              <PermissionRoute path='/bf/serverConfiguration' permissions={['ALLOWED_TO_SEE_SERVER_CONFIG']}>
                <Switch>
                  <TitledRoute
                    exact
                    path='/bf/serverConfiguration'
                    component={ServerConfiguration}
                    title='BF: Server Configuration'
                  />
                  <TitledRoute
                    exact
                    path='/bf/serverConfiguration/:view'
                    component={ServerConfiguration}
                    title='BF: Server Configuration ${view}'
                  />
                </Switch>
              </PermissionRoute>

              <TitledRoute
                exact
                path='/bf/devTools'
                component={DevTools}
                title={'BF: Development Tools'}
              />
              <TitledRoute
                path='/bf/devTools/:id'
                component={DevTools}
                title={'BF: Development Tools ${id}'}
              />
              <PermissionRoute
                path='/bf/contentdeliveryframework'
                component={BFCDFPage}
                title={'Best Fiends: Content Delivery Framework'}
                permissions={['ALLOWED_TO_SEE_CDF']}
              />

              <Redirect to='/404' />
            </Switch>
          </PermissionRoute>

          {/* PHOENIX */}
          <PermissionRoute path='/phoenix' permissions={['PHOENIX']}>
            <Switch>
              <PermissionRoute
                path='/phoenix/contentdeliveryframework'
                component={PhoenixCDFPage}
                title={'Phoenix: Content Delivery Framework'}
                permissions={['ALLOWED_TO_SEE_CDF']}
              />

              <PermissionRoute path='/phoenix/builds' permissions={['ALLOWED_TO_SEE_BUILD_ARCHIVER']}>
                <TitledRoute
                  exact
                  path='/phoenix/builds'
                  component={PhoenixBuildArchiver}
                  title={'Phoenix: Build Archiver'}
                />
                <TitledRoute
                  path='/phoenix/builds/:buildNumber'
                  component={PhoenixBuildArchiver}
                  title={'Phoenix: Build Archiver'}
                />
              </PermissionRoute>

              <PermissionRoute
                path='/phoenix/player'
                permissions={['ALLOWED_TO_EDIT_PLAYERS']}
                title={'Phoenix: Players'}
                component={PhoenixPlayers}
              />

              <PermissionRoute
                path='/phoenix/leaderboards'
                permissions={['ALLOWED_TO_SEE_LEADERBOARDS']}
                title={'Phoenix: Leaderboards'}
                component={PhoenixLeaderboards}
              />

              <PermissionRoute
                path='/phoenix/tournaments'
                permissions={['ALLOWED_TO_EDIT_PHOENIX_TOURNAMENT_GROUPS']}
                title={'Phoenix: Tournaments'}
                component={PhoenixTournaments}
              />

              <PermissionRoute
                path='/phoenix/eventManagement'
                permissions={['ALLOWED_TO_EDIT_PHOENIX_TOURNAMENT_GROUPS']}
                title={'Phoenix: Event management'}
                component={PhoenixEventConfigurations}
              />

              <PermissionRoute
                path='/phoenix/susan'
                permissions={['ALLOWED_TO_SEE_ANALYTICS']}
                title={'Phoenix: Segmentation management'}
                component={SegmentationManagementPage}
              />

              <PermissionRoute
                path='/phoenix/useroverrides'
                permissions={['ALLOWED_TO_SEE_ANALYTICS']}
                title={'Phoenix: Analytics overrides'}
                component={UserOverridesPage}
              />

              <PermissionRoute
                path='/phoenix/inventoryvalidator'
                permissions={['ALLOWED_TO_EDIT_PHOENIX_INVENTORY_VALIDATOR']}
                title={'Phoenix: Inventory Validator'}
                component={InventoryValidator}
              />

              <PermissionRoute
                path='/phoenix/serversettings'
                component={ServerSettings}
                title={'Phoenix: Server Settings'}
                permissions={['ALLOWED_TO_EDIT_PHOENIX_SERVER_SETTINGS']}
              />

              <PermissionRoute path='/phoenix/analytics' permissions={['ALLOWED_TO_SEE_ANALYTICS']}>
                <Switch>
                  <TitledRoute
                    path='/phoenix/analytics/:dashboard'
                    component={PhoenixAnalyticsPage}
                    title={'PHX: Analytics'}
                  />
                  <TitledRoute
                    path='/phoenix/analytics'
                    component={PhoenixAnalyticsPage}
                    title={'PHX: Analytics'}
                  />
                  <Redirect to='/404' />
                </Switch>
              </PermissionRoute>

              <PermissionRoute
                path='/phoenix/teams'
                component={PhoenixTeams}
                permissions={['ALLOWED_TO_EDIT_PHOENIX_TEAMS']}
              />

              <PermissionRoute
                path='/phoenix/inventories'
                component={InventoryManagement}
                permissions={['ALLOWED_TO_EDIT_PHOENIX_SERVER_SETTINGS']}
              />
            </Switch>
          </PermissionRoute>
          <TitledRoute path='/example/:id' component={ExamplePage} title={'Example'} />
          <Route exact path='/' component={EnvironmentsList} />
          <Route path='/404' component={NotFoundPage} />
          <Redirect to='/404' />
        </Switch>
      </Suspense>
    </App>
  );
};

const Routes = () => {
  return (
    <Switch>
      <Route path='/login' component={LoginPage} />
      <AuthenticatedRoute path='/' component={AuthenticatedRoutes} />
    </Switch>
  );
};

let routes = null;

export default function(permissionGuard) {
  if (routes) return routes;
  routes = Routes(permissionGuard);
  return routes;
}
