import { permissions } from '@seriously/common';
import companyLogo from './assets/seriouslyLogo.png';
import bf1Logo from './assets/bf1Logo.png';
import starsLogo from './assets/starsLogo.png';
import { NEW_PLAYER_PAGE_PATH, OLD_PLAYER_PAGE_PATH } from './constants/routePaths';

export const api_path = '/api';

export const sidebarItems = [
  {
    name: 'Company',
    icon: companyLogo,
    requiredPermissions: [permissions.COMPANY],
    children: [
      {
        name: 'Analytics KPI',
        path: '/company/analytics-kpi',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_ANALYTICS]
      },
      {
        name: 'Access & Erasure',
        path: '/company/access-and-erasure',
        requiredPermissions: [permissions.ALLOWED_TO_USE_ACCESS_AND_ERASURE]
      },
      {
        name: 'Ads',
        path: '/company/ads/ads',
        requiredPermissions: [permissions.ALLOWED_TO_EDIT_ADS]
      }
    ]
  },
  {
    name: 'Best Fiends',
    icon: bf1Logo,
    requiredPermissions: [permissions.BEST_FIENDS],
    children: [
      {
        name: 'Support Codes',
        path: '/bf/supportCode',
        requiredPermissions: [permissions.ALLOWED_TO_GENERATE_CODES]
      },
      {
        name: 'Players',
        path: NEW_PLAYER_PAGE_PATH,
        requiredPermissions: [permissions.ALLOWED_TO_SEE_PLAYERS]
      },
      {
        name: 'Fiendex',
        path: '/bf/fiendex',
        requiredPermissions: ['ALLOWED_TO_SEE_FIENDEX']
      },
      {
        name: 'Search',
        path: '/bf/search',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_PLAYERS]
      },
      {
        name: 'Live Ops Events',
        path: '/bf/liveOpsEvents',
        requiredPermissions: []
      },
      {
        name: 'Content Delivery Framework',
        path: '/bf/contentdeliveryframework',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_CDF]
      },
      {
        name: 'Version Config',
        path: '/bf/versionConfig',
        requiredPermissions: []
      },
      {
        name: 'Level Config',
        path: '/bf/levelConfig',
        requiredPermissions: []
      },
      {
        name: 'Level Releases',
        path: '/bf/level-releases',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_LEVEL_RELEASES]
      },
      {
        name: 'Analytics',
        path: '/bf/analytics',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_ANALYTICS]
      },
      /*{
                name: 'Cheater Detection',
                path: '/bf/cheaters',
                requiredPermissions: ['ALLOWED_TO_SEE_PLAYERS']
            },*/
      {
        name: 'Minutian News Center',
        path: '/bf/events',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_EVENTS]
      },
      {
        name: 'Leaderboards',
        path: '/bf/leaderboards',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_LEADERBOARDS]
      },
      {
        name: 'Build Archiver',
        path: '/bf/builds',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_BUILD_ARCHIVER]
      },
      {
        name: 'Server configuration',
        path: '/bf/serverConfiguration',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_SERVER_CONFIG]
      },
      {
        name: 'Dashboard configuration',
        path: '/bf/dashboardConfiguration',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_BF_DASHBOARD_CONFIGURATION]
      },
      {
        name: 'Development tools',
        path: '/bf/devTools',
        requiredPermissions: [permissions.ALLOWED_TO_EDIT_QA],
        developmentOnly: true
      },
      {
        name: 'Players Old (Deprecated)',
        path: OLD_PLAYER_PAGE_PATH,
        requiredPermissions: [permissions.ALLOWED_TO_SEE_PLAYERS]
      },
    ]
  },
  {
    name: 'Best Fiends: Stars',
    requiredPermissions: [permissions.PHOENIX],
    icon: starsLogo,
    children: [
      {
        name: 'Players',
        path: '/phoenix/player',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_PLAYERS]
      },
      {
        name: 'Teams',
        path: '/phoenix/teams',
        requiredPermissions: [permissions.ALLOWED_TO_EDIT_PHOENIX_TEAMS]
      },
      {
        name: 'Leaderboards',
        path: '/phoenix/leaderboards',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_LEADERBOARDS]
      },
      {
        name: 'Tournaments',
        path: '/phoenix/tournaments',
        requiredPermissions: [permissions.ALLOWED_TO_EDIT_PHOENIX_TOURNAMENT_GROUPS]
      },
      {
        name: 'Event management',
        path: '/phoenix/eventManagement',
        requiredPermissions: [permissions.ALLOWED_TO_EDIT_PHOENIX_TOURNAMENT_GROUPS]
      },
      {
        name: 'Content Delivery Framework',
        path: '/phoenix/contentdeliveryframework',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_CDF]
      },
      {
        name: 'Inventory Validator',
        path: '/phoenix/inventoryvalidator',
        requiredPermissions: [permissions.ALLOWED_TO_EDIT_PHOENIX_INVENTORY_VALIDATOR]
      },
      {
        name: 'Inventory Editor',
        path: '/phoenix/inventories',
        requiredPermissions: [permissions.ALLOWED_TO_EDIT_PHOENIX_INVENTORY_VALIDATOR]
      },
      {
        name: 'SUSan',
        path: '/phoenix/susan',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_ANALYTICS]
      },
      {
        name: 'Analytics overrides',
        path: '/phoenix/useroverrides',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_ANALYTICS]
      },
      {
        name: 'Server Settings',
        path: '/phoenix/serversettings',
        requiredPermissions: [permissions.ALLOWED_TO_EDIT_PHOENIX_SERVER_SETTINGS]
      },
      {
        name: 'Analytics',
        path: '/phoenix/analytics',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_ANALYTICS]
      },
      {
        name: 'Build Archiver',
        path: '/phoenix/builds',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_BUILD_ARCHIVER]
      }
    ]
  }
];
