import inventoryPrettyNames from './inventoryPrettyNames.json';

// RESOURCES
export const RESOURCE_GOLD = ['customUserData', 'gold'];
export const RESOURCE_DIAMONDS = ['customUserData', 'gems'];
export const RESOURCE_KEYS = ['customUserData', 'gachaKeys'];
export const RESOURCE_YELLOW_MITES = ['customUserData', 'upgradeResource0'];
export const RESOURCE_BLUE_MITES = ['customUserData', 'upgradeResource1'];
export const RESOURCE_GOLDEN_LEVELS = ['customUserData', 'dropGoldForLevels'];
export const RESOURCE_GOLDEN_TICKETS = ['customUserData', 'premiumGoldenEvents'];
export const RESOURCE_PENDING_UNLIMITED_ENERGY = ['energyData', 'pendingUnlimitedEnergy'];
export const RESOURCE_UNLIMITED_ENERGY_UNTIL_UTC = ['energyData', 'energyUnlimitedUntilUTC'];
export const RESOURCE_PENDING_VIP_TIME = ['energyData', 'pendingUnlimitedVIP'];
export const RESOURCE_SUBSCRIPTION_UNTIL = ['customUserData', 'subscriptionUntil'];
export const RESOURCE_SUBSCRIPTION_UNTIL_UTC = ['customUserData', 'subscriptionUntilUTC'];
export const RESOURCE_VIP_UNTIL_UTC = ['customUserData', 'vipUntilUTC'];
export const RESOURCE_REDEEMED_TRANSACTIONS = ['purchaseInformation2', 'redeemedTransactions'];
export const RESOURCE_FREE_FIEND_OF_FORTUNE_UNTIL = ['customUserData', 'freeFiendOfFortuneUntil'];
export const RESOURCE_ENERGY_CAP = ['energyData', 'energyBarCap'];
export const RESOURCE_PRE_LEVEL_BOOSTER_DOUBLE_BLOOM = ['customUserData', 'preLevelBoosterConverter'];
export const RESOURCE_PRE_LEVEL_FIRE_CRACKER = ['customUserData', 'preLevelBoosterClusterBomb'];
export const RESOURCE_PRE_LEVEL_BOOSTER_MAGIC_STAFF = ['customUserData', 'preLevelBoosterFury'];
export const RESOURCE_PRE_LEVEL_BOOSTER_ROCKET = ['customUserData', 'preLevelBoosterRocket'];
export const RESOURCE_PRE_LEVEL_BOOSTER_SLUG_SPRAY = ['customUserData', 'preLevelBoosterSlugAttack'];

// LIVE OPS EVENTS
export const LIVE_OPS_EVENTS_LAST_EVENT_TIME = ['liveOpsEvents', 'lastEventTimeUTC_fwdCompatible'];

// PLATFORMS
export const HAS_PLAYED_ON_AMAZON = ['customUserData', 'hasPlayedOnAmazon'];
export const HAS_PLAYED_ON_ANDROID = ['customUserData', 'hasPlayedOnAndroid'];
export const HAS_PLAYED_ON_FACEBOOK = ['customUserData', 'hasPlayedOnFacebook'];
export const HAS_PLAYED_ON_IOS = ['customUserData', 'hasPlayedOnIOS'];

// PROGRESS
export const WORLD_PROGRESS = ['progress'];
export const HERO_PROGRESS = ['heroArea', 'progress'];
export const FIEND_QUEST_LEVELS_COMPLETED_TOTAL = ['Fiend_Quest_TotalProgress_NewFormat'];

// EARTHWORM QUESTS
export const EARTHWORM_LEVELS_COMPLETED_TOTAL = ['EarthwormQuests', 'totalLevelProgress'];
export const EARTHWORM_TOTAL_COLLECTED_REWARD_COUNT = ['EarthwormQuests', 'totalCollectedRewardCount'];
export const EARTHWORM_TOTAL_STARS = ['EarthwormQuests', 'totalStars'];
export const EARTHWORM_TOTAL_GOLD_SPEND = ['EarthwormQuests', 'totalGoldSpend'];
export const EARTHWORM_CAPTAIN_QUEST = ['EarthwormQuests', 'captainData'];
export const EARTHWORM_GENERAL_QUEST = ['EarthwormQuests', 'generalData'];
export const EARTHWORM_GRANNY_QUEST = ['EarthwormQuests', 'grannyData'];

// OTHER
export const CUSTOM_USER_DATA = ['customUserData'];
export const ALL_TIME_MONEY_SPEND_IN_DOLLARS = ['purchaseInformation2', 'allTimeMoneySpendInDollars'];
export const COPPA_BIRTH_YEAR = ['customUserData', 'coppaBirthYear'];
export const SAVE_VERSION = ['SAVE_VERSION'];
export const FIRST_INSTALL_VERSION = ['FirstVersionAfterInstall'];
export const FIRST_INSTALL_DATE = ['FirstInstallDate'];
export const LAST_SESSION_DATE = ['customUserData', 'lastSaveDeviceTimeUTC'];
export const LAST_PURCHASE_DATE = ['purchaseInformation2', 'allTimeMoneySpendInDollars'];
export const PENDING_REDEEM_CODE = ['customUserData', 'pendingRedeemCode'];
export const BANNED_UNTIL_DATE = ['customUserData', 'bannedUntilDate'];
export const LATEST_SERVER_TIME_UTC = ['customUserData', 'latestServerTimeUTC'];
export const MARK_INVENTORY_FOR_RESET = ['MarkInventoryForReset'];
export const CURRENT_WIN_STREAK = ['customUserData', 'winStreak'];
export const LONGEST_WIN_STREAK = ['customUserData', 'longestWinStreak'];
export const CURRENT_COLLECTED_DAILY_GIFTS = ['customUserData', 'dailyGiftCollectedCounter'];
export const DAILY_GIFT_CALENDARS_COMPLETED_TOTAL = ['customUserData', 'dailyGiftsCalendarCompletedCounter'];
export const ACTIVE_SUPPORT_TICKET_ID = ['customUserData', 'activeSupportTicketId'];
export const FLASH_EVENT_UNTIL_UTC = ['customUserData', 'flashEventUntilUtc'];
export const FIEND_COUNT_NEW_FORMAT = ['fiendCountNewFormat'];
export const ARCHIVE_EVENTS_VERSION = ['archiveEventsVersion'];
export const USER_FLAGS_PREFIX = ['customUserData', 'userFlags'];
export const DISABLE_VIDEO_ADS = ['customUserData', 'disableVideoAds'];
export const ALLOW_TARGETED_ADS = ['customUserData', 'allowTargetedAds'];
export const WIN_STREAK_BEFORE_CRASH = ['customUserData', 'winStreakBeforeCrash'];
export const WIN_STREAK_LEVEL_CRASH_IMMUNITY_USED = ['customUserData', 'winStreakLevelCrashImmunityUsed'];
export const WIN_STREAK_LEVEL_CRASH_IMMUNITY_LIMIT_OFFSET = ['customUserData', 'winStreakLevelCrashImmunityLimitOffset'];

export function mapToPrettyNameOrPath(path: string[]): string {
  const joinedPath = path.join('.');

  for (const prettyName of Object.entries(inventoryPrettyNames)) {
    const [key, value] = prettyName;
    
    if (key === joinedPath) {
      return value;
    }
  }

  return joinedPath;
}
